import { MutableRefObject, useEffect, useRef } from "react";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import { ARROW_RIGHT, CHEVRON_RIGHT, Icon } from "components/ui";

import {
    Anchor,
    Container,
    List,
    ListItem,
    StyledIcon,
    Text,
    Title
} from "./SecondarySubMenu.styled";

import { useMediaQueryContext } from "helpers/hooks";
import { useMenuContext } from "helpers/hooks/useMenuContext";

const ArrowIcon = (
    isDesktopView: boolean,
    showIconOnDesktop = false
): JSX.Element | undefined => {
    if (isDesktopView && showIconOnDesktop) {
        return (
            <StyledIcon
                icon={ARROW_RIGHT}
                size={24}
                color="var(--color-orange-50)"
                aria-hidden="true"
            />
        );
    }

    if (!isDesktopView) {
        return (
            <Icon
                icon={CHEVRON_RIGHT}
                size={24}
                color="var(--color-orange-50)"
                aria-hidden="true"
            />
        );
    }
};

export function SecondarySubMenu(): JSX.Element {
    const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
    const { activeItem, setMenuHeight } = useMenuContext();
    const { isMobileView, isDesktopView } = useMediaQueryContext();
    const { t } = useTranslation();
    const isOpen: boolean = activeItem === "freelancer";

    useEffect(
        function setForegroundHeight() {
            const submenuHeight: number = ref.current.clientHeight || 0;

            if (isOpen && isMobileView) {
                setMenuHeight(submenuHeight);
            }
        },
        [isMobileView, isOpen, ref, setMenuHeight]
    );

    return (
        <Container
            ref={ref}
            isOpen={isOpen}
            id="menu-freelancer"
            role={isDesktopView ? "group" : "tabpanel"}
            aria-hidden={!isOpen}
        >
            {isMobileView && <Title>{t("common:flex")}</Title>}
            <List>
                <ListItem isFirst>
                    <Link
                        legacyBehavior
                        href="/zzper"
                        prefetch={false}
                        passHref
                    >
                        <Anchor>
                            <Text>{t("common:freelance")}</Text>
                            {ArrowIcon(isDesktopView, true)}
                        </Anchor>
                    </Link>
                </ListItem>

                <ListItem>
                    <Link
                        legacyBehavior
                        href="/uitzenden"
                        prefetch={false}
                        passHref
                    >
                        <Anchor>
                            <Text>{t("common:tempWorker")}</Text>
                            {ArrowIcon(isDesktopView, true)}
                        </Anchor>
                    </Link>
                </ListItem>
            </List>
        </Container>
    );
}
