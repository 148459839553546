import { useEffect, useState } from "react";
import Image from "next/legacy/image";
import LeadLogo from "public/images/logos/lhc-circle-logo-2024.svg";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import { HeaderBackground } from "./background";
import {
    Logo,
    LogoContainer,
    StickyHeader,
    StyledContainer,
    StyledHeader,
    Wrapper
} from "./Header.styled";
import { Navigation } from "./navigation";
import { Preheader, PreheaderLinks } from "./preheader";

import { MenuContextProvider } from "helpers/hooks/useMenuContext";

type Props = {
    hideStickyHeader?: boolean;
};

export function Header({ hideStickyHeader }: Props): JSX.Element {
    const [showStickyBar, setShowStickyBar] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        function determineDistance() {
            if (
                (window.scrollY > 48 && window.innerWidth >= 744) ||
                (window.scrollY > 32 && window.innerWidth < 744)
            ) {
                setShowStickyBar(true);
            } else {
                setShowStickyBar(false);
            }
        }

        window.addEventListener("scroll", determineDistance);

        return () => {
            window.removeEventListener("scroll", determineDistance);
        };
    }, []);

    return (
        <MenuContextProvider>
            <StyledHeader>
                <Wrapper>
                    <StyledContainer>
                        <LogoContainer>
                            <Link legacyBehavior href="/" passHref>
                                <a aria-label="Leadhealthcare.nl homepage">
                                    <Logo
                                        src="/images/logos/lhc-logo-full-black-orange.png"
                                        alt="Lead Healthcare Logo"
                                        loading="eager"
                                        width={248}
                                        height={56}
                                    />
                                </a>
                            </Link>
                        </LogoContainer>
                        <PreheaderLinks className="preheader-in-menu" />
                        <Navigation stickyBarActive={showStickyBar} />
                    </StyledContainer>
                </Wrapper>
                <Preheader />
            </StyledHeader>

            {!hideStickyHeader && (
                <StickyHeader className={showStickyBar ? "active" : ""}>
                    <Wrapper>
                        <StyledContainer
                            className={showStickyBar ? "sticky" : ""}
                            type="flex"
                        >
                            <LogoContainer>
                                <Link legacyBehavior href="/" passHref>
                                    <a aria-label="Leadhealthcare.nl homepage">
                                        <Image
                                            src={LeadLogo}
                                            height="48"
                                            width="48"
                                        />
                                    </a>
                                </Link>
                                <span>
                                    {t("common:preheader", {
                                        years: 18
                                    })}
                                </span>
                            </LogoContainer>

                            <PreheaderLinks
                                className="preheader-in-sticky-bar"
                                showNewsButton={false}
                            />
                        </StyledContainer>
                    </Wrapper>
                </StickyHeader>
            )}

            <HeaderBackground stickyBarActive={showStickyBar} />
        </MenuContextProvider>
    );
}
