import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";

import { Subtitle } from "components/ui";

import { StyledContainer, Wrapper } from "./Preheader.styled";

import { useMediaQueryContext } from "helpers/hooks";

const PreheaderLinks = dynamic(
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    //@ts-ignore
    () => import("./links").then(m => m.PreheaderLinks),
    { ssr: false }
);

export function Preheader(): JSX.Element {
    const { isMobileView } = useMediaQueryContext();
    const { t } = useTranslation();

    return (
        <Wrapper className="preheader">
            <StyledContainer>
                <Subtitle>
                    {t("common:preheader", {
                        years: 18
                    })}
                </Subtitle>
                {!isMobileView && (
                    <nav role="navigation" aria-label="Preheader">
                        {/* eslint-disable @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore*/}
                        <PreheaderLinks showNewsButton={false} />
                    </nav>
                )}
            </StyledContainer>
        </Wrapper>
    );
}
