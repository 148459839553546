import { SbBlokData, StoryData } from "@storyblok/react/dist/types/types";
import { format, parseISO } from "date-fns";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import Link from "next/link";
import nl from "date-fns/locale/nl";

import { ARROW_RIGHT, Icon } from "components/ui";
import {
    Article,
    ArticleBottom,
    ArticleContent,
    ArticleDate,
    ArticleTitle,
    Articles,
    ImageWrapper,
    LatestNewsArticlesWrapper,
    StyledArrow,
    StyledLink,
    StyledTitle
} from "components/homepage/latestNewsArticles/LatestNewsArticles.styled";

type LatestNewsArticlesProps = SbBlokData & {
    blok: {
        title?: string;
        articles?: Array<StoryData>;
        linkText?: string;
        link?: {
            id?: string;
            url?: string;
            cached_url?: string;
        };
        _uid: string;
        component: string;
    };
};

const dateTimeIsoRegex = /\d{4}(.\d{2}){2}(\s|T)(\d{2}.){2}\d{2}/g;

export function LatestNewsArticles({ blok }: LatestNewsArticlesProps) {
    const { articles, title, linkText, link } = blok || {};

    // When changing fields in the storyblok editor, the resolved relations in this component (articles) are not resolved with the changes resulting in an empty array.
    // To prevent the page from breaking, we check if there's any data in the articles array. If not, we return an empty fragment.
    if (!articles?.length || !articles[0]?.name) {
        return <></>;
    }

    return (
        <LatestNewsArticlesWrapper {...storyblokEditable(blok)}>
            {title && <StyledTitle>{title}</StyledTitle>}

            <Articles>
                {articles?.map(article => (
                    <Article
                        href={article.full_slug}
                        passHref
                        key={article.uuid}
                    >
                        <ImageWrapper>
                            <Image
                                src={
                                    article.content?.image?.filename +
                                    "/m/368x152"
                                }
                                alt={article.content?.image?.alt || ""}
                                fill
                                unoptimized
                                style={{ objectFit: "cover" }}
                            />
                        </ImageWrapper>

                        <ArticleContent>
                            <ArticleTitle>{article.name}</ArticleTitle>

                            <ArticleBottom>
                                <ArticleDate>
                                    {article.created_at &&
                                        !!article.created_at.match(
                                            dateTimeIsoRegex
                                        ) &&
                                        format(
                                            parseISO(article.created_at),
                                            "d MMMM YYY",
                                            {
                                                locale: nl
                                            }
                                        )}
                                </ArticleDate>
                                <StyledArrow
                                    icon={ARROW_RIGHT}
                                    size={40}
                                    color="var(--color-primary)"
                                />
                            </ArticleBottom>
                        </ArticleContent>
                    </Article>
                ))}
            </Articles>

            {linkText && link && (
                <Link
                    legacyBehavior
                    href={link?.url || link?.cached_url || ""}
                    passHref
                >
                    <StyledLink>
                        {linkText}

                        <Icon
                            icon={ARROW_RIGHT}
                            size={24}
                            color="var(--color-primary)"
                        />
                    </StyledLink>
                </Link>
            )}
        </LatestNewsArticlesWrapper>
    );
}
