import { OrganisationApplyFormProps } from "storyblok/components";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import Link from "next/link";
import ReactDOMServer from "react-dom/server";

import { ARROW_RIGHT, CHECK, Heading } from "components/ui";
import {
    ApplyPageBackground,
    Description,
    FormDisclaimer,
    FormErrorButton,
    FormErrorWrapper,
    StyledAnchor,
    StyledApplyForm,
    StyledButton,
    StyledInput,
    StyledOrganisationApplyForm,
    SubmitButtonWrapper,
    SuccessBlock,
    SuccessIcon,
    SuccessTitle,
    TextAreaWrapper
} from "components/organisation/applyForm/OrganisationApplyForm.styled";
import { TextArea } from "components/ui/textArea";

interface FormInputFields {
    fullName: string;
    email: string;
    phone: string;
    companyName: string;
    message: string;
}

export function OrganisationApplyForm({
    blok
}: OrganisationApplyFormProps): JSX.Element {
    const { title, description, submitButtonText } = blok;

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [hasErrorInForm, setHasErrorInForm] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [submitterData, setSubmitterData] = useState<{
        name: string;
        email: string;
    }>();

    const {
        handleSubmit,
        register,
        setValue,
        reset,
        formState: { errors }
    } = useForm<FormInputFields>();

    // eslint-disable-next-line
    const onSubmit: SubmitHandler<any> = async function (
        data: FormInputFields
    ) {
        setIsFormLoading(true);

        // eslint-disable-next-line
        const emailBodyHTML = ReactDOMServer.renderToStaticMarkup(
            <div>
                <b>Naam:</b> {data.fullName}
                <br />
                <br />
                <b>Email:</b> {data.email}
                <br />
                <br />
                <b>Telefoonnummer:</b> {data.phone}
                <br />
                <br />
                <b>Bedrijfsnaam:</b> {data.companyName}
                <br />
                <br />
                <b>Bericht:</b>
                <br />
                <p>{data.message}</p>
                <br />
            </div>
        );

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const response = await fetch(`/api/mail`, {
            body: JSON.stringify({
                from: "Noreply <noreply@leadhealthcare.nl>",
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                message: emailBodyHTML,
                subject: `Aansluitformulier ${data.fullName} (${data.companyName})`,
                to: process.env.MAILGUN_RECEIVER
            }),
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST"
        });

        if (response.status === 200) {
            reset();
            setSubmitterData({ email: data.email, name: data.fullName });
            setIsFormSubmitted(true);
            setIsFormLoading(false);
        } else {
            setHasErrorInForm(true);
            setIsFormLoading(false);
        }
    };

    const formErrorBlock = (
        <FormErrorWrapper>
            Er is iets mis gegaan bij het versturen van het formulier, probeer
            het later nog eens.
            <FormErrorButton
                buttonType="primary"
                onClick={() => setHasErrorInForm(false)}
            >
                Opnieuw proberen
            </FormErrorButton>
        </FormErrorWrapper>
    );

    const formSuccessBlock = (
        <SuccessBlock id="gtm-form-submitted">
            <SuccessTitle>
                <SuccessIcon
                    icon={CHECK}
                    size={30}
                    color="var(--color-green-50)"
                />
                Je aanvraag is verstuurd
                {submitterData && submitterData.name
                    ? `, ${submitterData.name}!`
                    : "!"}
            </SuccessTitle>
            Gelukt! Je aanvraag om je als opdrachtgever aan te sluiten bij Lead
            Healthcare is verstuurd. Je ontvangt een bevestiging
            {submitterData && submitterData.email ? (
                <b>{` op ${submitterData.email}. `}</b>
            ) : (
                ". "
            )}{" "}
            Wij proberen je binnen twee werkdagen van reactie te voorzien!
        </SuccessBlock>
    );

    /* Registering the textarea separately because the component doesn't for allow direct registers */
    register("message");

    return (
        <StyledOrganisationApplyForm>
            <ApplyPageBackground />
            <StyledApplyForm>
                <Heading headingLevel={1}>{title}</Heading>
                <Description>{description}</Description>
                {hasErrorInForm ? (
                    formErrorBlock
                ) : isFormSubmitted ? (
                    formSuccessBlock
                ) : (
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <StyledInput
                            label="Naam"
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            errorMessage={errors.fullName?.message}
                            {...register("fullName", {
                                required: {
                                    message: "Vul een naam in",
                                    value: true
                                }
                            })}
                        />
                        <StyledInput
                            label="E-mail"
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            errorMessage={errors.email?.message}
                            {...register("email", {
                                pattern: {
                                    message: "Vul een geldig emailadres in",
                                    value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
                                },
                                required: {
                                    message: "Vul een emailadres in",
                                    value: true
                                }
                            })}
                        />
                        <StyledInput
                            label="Telefoonnummer"
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            errorMessage={errors.phone?.message}
                            {...register("phone", {
                                pattern: {
                                    message: "Vul een geldig telefoonnummer in",
                                    value: /\+?\d+/
                                },
                                required: {
                                    message: "Vul een telefoonnummer in",
                                    value: true
                                }
                            })}
                        />
                        <StyledInput
                            label="Bedrijfsnaam"
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            errorMessage={errors.companyName?.message}
                            {...register("companyName", {
                                required: {
                                    message: "Vul een bedrijfsnaam in",
                                    value: true
                                }
                            })}
                        />
                        <TextAreaWrapper>
                            <TextArea
                                label="Bericht"
                                onChange={e =>
                                    setValue(
                                        "message",
                                        // eslint-disable-next-line
                                        // @ts-ignore
                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                        e.target.value
                                    )
                                }
                            />
                        </TextAreaWrapper>
                        <SubmitButtonWrapper>
                            <FormDisclaimer>
                                Door op de knop “{submitButtonText}” te klikken,
                                ga je akkoord met onze{" "}
                                <Link
                                    href="/algemene-voorwaarden"
                                    passHref
                                    prefetch={false}
                                >
                                    <StyledAnchor>
                                        gebruiksvoorwaarden
                                    </StyledAnchor>
                                </Link>{" "}
                                en ons{" "}
                                <Link
                                    href="/privacy-cookies"
                                    passHref
                                    prefetch={false}
                                >
                                    <StyledAnchor>privacybeleid</StyledAnchor>
                                </Link>
                                .
                            </FormDisclaimer>
                            <StyledButton
                                buttonType="primary"
                                icon={ARROW_RIGHT}
                                reversedOrder
                                disabled={isFormLoading}
                            >
                                {submitButtonText}
                            </StyledButton>
                        </SubmitButtonWrapper>
                    </form>
                )}
            </StyledApplyForm>
        </StyledOrganisationApplyForm>
    );
}
